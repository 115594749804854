.latest-news {

  &__title {
    @apply pb-14;

    .block__title {
      @apply text-darkpurple;
    }
  }

  &__action {
    @apply flex justify-center pt-16;
  }

}

.news-list {

  &__item {
    @apply pb-8 mb-8;
    border-bottom: 2px solid theme('colors.darkpurple');

    &:last-child {
      @apply mb-0;
    }

    &__title {
      @apply pb-3 font-bodymedium text-darkpurple;
    }

    &__summary {
      @apply text-darkgrey mb-6;

      p {
        @apply mb-0;
      }
    }

  }

}