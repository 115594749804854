.content-block {

  &__title {
    @apply text-left;
  }

  &__text {
    @apply text-darkgrey;
  }

  &__actions {
    @apply flex flex-wrap justify-start mt-16;

    .button {
      @apply mx-2 mb-4 pb-3;
    }
  }

  a {
    @apply text-lightblue;
    border-bottom: 2px solid rgba(45, 107, 180, var(--tw-text-opacity));
    padding-bottom: 1px;
    transition: all 0.2s linear;
  }

  a:hover {
    @apply text-blue;
  }

  h1, h2, h4 {
    @apply mb-5 text-darkpurple;
  }

  h3, .h3 {
    @apply font-bodymedium mb-4;
  }

  h4, .h4 {
    @apply font-bodymedium mb-4;
    font-size: 22px;
    line-height: 26px;
  }

  h5, .h5, h6, .h6 {
    @apply font-bodymedium mb-4;
    font-size: 18px;
    line-height: 22px;
  }

  h1, h2 {
    @apply text-purple;
  }

  h3, h4, h5, h6 {
    @apply text-blue;
  }

  ul, ol {
    @apply mb-4;
    //@apply mb-16;
  }

  ul {

    li {
      @apply relative mb-2 pl-6;

      &:before {
        @apply bg-darkgrey absolute mr-3;
        content: '';
        width: 13px;
        height: 1px;
        top: 13px;
        left: 0;
      }
    }

  }

  @screen md {

    &__actions {
      @apply justify-center;
      .button {
        @apply mb-0;
      }
    }

  }

}