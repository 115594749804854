.form {

  &__title {
    @apply mb-7;
  }

  &__content {
    @apply text-center mb-12;
  }

  .ff-form-errors.text-error,
  .ff-form-errors {
    @apply py-2 px-2 text-errors mb-12 border-errors rounded border;
    background: #ffbdc8;

    p {
      @apply m-0;
    }
  }

  .ff-form-success {
    @apply py-2 px-2 text-center mb-12 border-green rounded-2xl border;
    background: #EEF5E5;

    p {
      @apply m-0;
    }
  }


  &__fields {
    @apply -mx-2 flex flex-wrap;

    .field {
      @apply w-full px-2 mb-4;
    }

    .instructions {
      @apply text-sm block pb-1 pt-1;
    }

    .form-label {
      @apply block;
      font-size: 18px;
    }

    .field--checkbox {
      @apply flex justify-end flex-row-reverse items-center;
      flex-wrap: nowrap;
      input {
        @apply mr-3;
        align-self: flex-start;
      }
    }

    .field--radio_group {
      .form-label {
        @apply mb-4;
      }

      &.inline-options {
        label {
          @apply inline-flex mr-5;
        }
      }

      label {

        @apply flex mb-4;

        input {
          @apply mr-3;
        }
      }
    }

    .field--dynamic_recipients {

      &.inline-options {
        label {
          @apply inline-flex mr-5;
        }
      }

    }

    .field--checkbox_group {
      .form-label {
        @apply mb-4;
      }

      &.inline-options {
        label {
          @apply inline-flex mr-5;
        }
      }

      label {
        @apply flex items-center justify-start mb-4;

        input {
          @apply mr-3 mt-0;
        }
      }
    }
  }

  input,
  textarea,
  select {
    &:focus {
      outline: none;
    }
  }

  input[type="radio"] {
    @apply w-6 h-6 rounded-full border-darkgrey mt-0 text-purple;
    appearance: none;
  }

  input[type="checkbox"] {
    @apply w-6 h-6 rounded border-darkgrey text-purple;
    appearance: none;
  }

  .errors,
  .ff-errors {
    @apply font-bold text-errors w-full;

    li {
      @apply pt-1;
    }
  }

  @screen md {
    &__fields {

      .field {
        @apply w-1/2;

        &--full {
          @apply w-full;
        }
      }

    }
  }

}

.form__fields .field--radio_group .form-label + .instructions {
  @apply pt-0 -mt-2;
}

select,
input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
textarea {
  @apply m-0 border-darkgrey;
  &:focus {
    @apply ring-purple;
  }
}

input[type="checkbox"],
input[type="radio"] {
  @apply w-4 h-4;
  &:focus {
    @apply ring-purple;
  }
}
