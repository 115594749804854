.footer {
  @apply text-white py-10;
  background: linear-gradient(164deg, #211F6E, #AA33C6 97%);

  &__container {
    @apply flex justify-between flex-wrap;
  }

  &__logo {
    @apply mb-8;
    width: 195px;

    svg {
      @apply w-full;
    }
  }

  &__nav {
    @apply w-full;

    a {
      @apply border-transparent border-b transition-all;


      &:hover {
        @apply border-white;
      }
    }
  }

  &__col {
    @apply mb-5;

    ul {
      @apply mb-0;
    }

    li {
      @apply mb-2;
    }
  }

  &__phone {
    @apply font-bold inline-flex items-center;

    svg {
      @apply mr-3;
    }
  }

  &__note {
    @apply w-full pt-5;

    li {
      @apply mb-2;
    }

    a {
      @apply border-transparent border-b transition-all;

      &:hover {
        @apply border-white;
      }
    }
  }

  @screen md {

    @apply py-16;

    &__nav {
      @apply flex w-auto;
    }

    &__col {
      @apply mb-0;
      margin-left: 80px;

      &:first-child {
        @apply ml-0;
      }
    }

    &__note {
      @apply flex justify-end pt-20;

      ul {
        @apply flex;
      }

      li {
        @apply ml-6 mb-0;

        &:first-child {
          @apply ml-0;
        }
      }
    }

  }

  @screen lg {
    &__logo {
      @apply mb-0;
    }
  }

}