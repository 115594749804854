.logos {

  &__content {
    @apply mb-8;
  }

  &__description {
    @apply text-center mb-6;
  }

  &__grid {
    @apply flex flex-wrap justify-start -mx-5;
  }

  &__item {

    @apply px-5 flex-shrink-0 flex-grow-0 w-1/2;

    &__holder {
      @apply relative block;

      img {
        @apply absolute;

        max-width: 90%;
        max-height: 90%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &:before {
        @apply block;

        content: " ";
        width: 100%;
        height: 140px;
      }

    }

  }

  &__more {
    @apply flex justify-center pt-8;

    svg {
      @apply ml-2.5;
    }
  }

  @screen md {

    &__item {
      @apply w-1/4;
    }

  }

  @screen lg {

    &__item {
      @apply w-1/5;
    }

  }

}