.columns {

  &__grid {
    @apply flex flex-wrap justify-between;
  }

  &__column {
    @apply w-full mb-6;

    h2 {
      @apply font-bodymedium mb-6 text-darkpurple;
      font-size: 28px;
      line-height: 32px;
    }

    &__text {
      @apply pb-6;

      p:last-child {
        @apply mb-0;
      }

      a {
          @apply font-headings border-b border-darkpurple text-darkpurple transition ease-in-out duration-300;
          &:hover {
            @apply border-transparent;
          }
      }
    }
  }

  &__actions {
    @apply flex flex-wrap justify-start;

    .button {
      @apply mr-2 mb-2;

      &:last-child {
        @apply mr-0;
      }
    }
  }

  @screen md {
    &__column {
      width: 46%;
    }
  }

}
