.introduction {

  &__text {
    @apply text-center;
  }

  &__title {
    @apply mb-6;
  }

  &__actions {
    @apply flex justify-center pt-3;
  }


}