.accordion {

  &__title {
    @apply mb-8;
  }

  &__category {
    @apply mb-16;

    &:last-child {
      @apply mb-0;
    }

    &__title {
      @apply font-bodymedium mb-8 text-darkpurple;
    }
  }

  &__item.active {
    @apply border-purple;
    .text {
      @apply text-purple;
    }
  }

  &__item:first-child {
    border-top: 2px solid theme('colors.darkpurple');
  }

  &__item {
    border-bottom: 2px solid theme('colors.darkpurple');

    &__title {
      @apply flex justify-between items-center py-4 text-base font-button cursor-pointer;

      .expand,
      .collapse {
        @apply w-6 h-6 block flex justify-center items-center;
      }

      .collapse {
        @apply hidden;
      }

      .active & {
        .collapse {
          @apply block;
        }

        .expand {
          @apply hidden;
        }
      }
    }

  }

  &__content {
    @apply hidden pr-8;

    .active & {
      @apply block;
    }
  }

}