.notice {
  @apply py-10 text-white bg-blue;
  width: 100vw;

  &__title {
    @apply mb-4 overflow-hidden font-body font-normal;
    letter-spacing: 0.025rem;

    svg {
      @apply float-left mr-3 relative;
      top: 5px;
    }

  }

  &__desc {
    @apply mb-6 text-lg;
  }

  &__close {
    @apply absolute top-5 right-5 cursor-pointer;
  }

}