.video {

  &__title {
    @apply mb-16;
  }

  &__image {
    @apply block relative mx-auto;
    max-width: 912px;
    background: #F1F1F1;

    &:before {
      @apply block w-full;
      content: " ";
      padding-top: 56.4692982%;
    }

    img {
      @apply absolute top-0 left-0 w-full h-full;
    }

    svg {
      @apply absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    circle, path {
      @apply transition-all;
    }

    &:hover {
      circle {
        fill: theme('colors.lightblue');
      }

      path {
        fill: white;
      }
    }
  }
}