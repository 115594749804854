.hero {
  @apply relative text-white flex flex-wrap items-end justify-end;
  min-height: 250px;
  background: linear-gradient(135deg, #211F6E 10%, #AA33C6 60%, #F36E5B 90%);

  &__image {
    @apply relative top-0 left-0 w-full h-full bg-cover bg-no-repeat;
    background-position: top center;
  }

  &--regular {
    .pattern {
      @apply w-1/4 bottom-0 right-0 absolute max-w-md;
    }
    @screen md {
      .pattern {
        @apply w-1/6;
      }
    }
    .hero__image {
      &--left {
        position: absolute;
        width: 45%;
        background-size: auto 100%;
        background-position: right center;
      }

      &--right {
        position: absolute;
        width: 45%;
        left: auto;
        right: 0;
        background-size: auto 100%;
        background-position: left center;
      }

      @screen lgxx {
        &--left {
          background-position: left center;
        }

        &--right {
          background-position: right center;
        }
      }
    }
  }

  .container {
    @apply relative z-10;
  }

  &__content {
    padding-top: 140px;
    padding-bottom: 80px;
  }

  &__text {
    font-size: 20px;
    line-height: 28px;
  }

  &__title {
    @apply font-headings text-center font-normal mb-0;
    margin-bottom: 22px;
    font-size: 32px;
    line-height: 38px;
  }

  &__actions {
    @apply mt-8 flex flex-wrap justify-start;

    .button {
      @apply text-center justify-center;
    }

    .button--primary {
      @apply mb-4;
      min-width: 201px;
    }

    .button--hollow {
      min-width: 230px;
    }
  }

  &--large {
    @apply h-full;
    min-height: 100vh;

    .pattern {
      @apply block w-4/5 mt-10 bottom-0 right-0 max-w-3xl;
    }

    .hero__title {
      @apply text-left mb-4;
      font-size: 32px;
      line-height: 38px;
    }

    .hero__content {
      @apply pb-0;
      padding-top: 120px;
    }

    .hero__image {
      //@apply relative top-0 left-0 w-full h-full bg-center bg-cover bg-no-repeat;
      height: 300px;
      margin-top: -48px;
    }

    @screen md {
      @apply h-auto;
      min-height: 75vh;

      .pattern {
        @apply mt-0 absolute w-1/3;
      }

      .hero__content {
        padding-top: 140px;
      }
    }

    @screen xl {
      .pattern {
        @apply w-2/5
      }
    }

    @screen xxxl {
      .pattern {
        @apply w-3/5;
      }
    }

  }

  @screen md {
    background: linear-gradient(144deg, #211F6E 10%, #AA33C6 60%, #F36E5B 90%);
    min-height: 328px;
    @apply items-center justify-start;

    &__text {
      font-size: 22px;
      line-height: 32px;
    }

    &__image {
      @apply absolute top-0 left-0 w-full h-full bg-cover bg-no-repeat;
      background-position: top center;

      //&--left {
      //  width: 50%;
      //  background-size: auto 100%;
      //  background-position: left center;
      //}
      //
      //&--right {
      //  left: 50%;
      //  width: 50%;
      //  background-size: auto 100%;
      //  background-position: right center;
      //}

      //background-size: 100% auto;
      //background-position: bottom center;
    }

    &__title {
      font-size: 72px;
      line-height: 86px;
    }

    &__content {
      padding-top: 236px;
    }

    &--large {

      .hero__title {
        @apply mb-6;
        font-size: 60px;
        line-height: 72px;
      }

      .hero__image {
        @apply absolute h-full m-0;

      }

      .hero__content {
        padding-top: 297px;
        padding-bottom: 180px;
        max-width: 780px;
      }

    }

    &__actions {
      @apply mt-8 flex flex-wrap justify-start;

      .button:first-child {
        @apply mr-4 mb-0;
      }
    }

  }
}