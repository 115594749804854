.mobile-menu {
  @apply absolute w-full z-50 py-8;
  top: 96px;
  left: 0;
  height: calc(100vh - 96px);
  background: white;
  transform: translateX(100%);
  transition: transform 0.25s linear;

  a {
    @apply block;
  }

  a span {
    @apply flex items-center px-6 py-2;
  }

  a.active,
  a:hover {
      @apply text-purple bg-white;
  }


  ul {
    @apply mb-0;

    ul {
      li {

        span {
          &:before {
            @apply block bg-darkgrey flex-grow-0 flex-shrink-0 mr-3;
            content: " ";
            width: 13px;
            height: 1px;
          }
        }

      }
    }
  }

}

.active-mm {
  .mobile-menu {
    transform: translateX(0);
  }
}