.tiles {

  @apply py-16;

  &--darkpurple {
    @apply bg-darkpurple;

    .tiles__title {
      @apply text-white;
    }

    .tiles__description {
      @apply text-white;
    }

  }

  &__title {
    @apply text-center pb-8 text-purple;
  }

  &__description {
    @apply text-center;
  }

  &__grid {
    @apply -mx-4 flex flex-wrap justify-center;
  }

}

.tile {
  @apply flex px-4 mb-4 text-center w-full flex-shrink-0;

  &__inner {
    @apply flex flex-col py-8 px-6 bg-white rounded-2xl w-full;
  }

  &__image {
    @apply mb-6;

    img {
      @apply w-full;
    }
  }

  &__title {
    @apply mb-3 font-bodymedium text-darkpurple;
  }

  &__content {
    @apply flex-grow mb-6;

    p {
      @apply mb-0;
      font-size: 16px;
      line-height: 1.5;
    }
  }

  &__action {
    @apply flex justify-center;
  }

  .tiles--3 & {
    &__inner {
      @apply border-2 border-darkpurple;
    }
  }

  .tiles--6 & {

    &__title {
      @apply text-darkpurple;
      font-size: 22px;
    }

    &__inner {
      @apply px-0;
    }

    &__image {
      @apply items-center flex justify-center;
      height: 120px;
    }

    &__image img {
      max-width: 70%;
      max-height: 100%;
      width: auto;
    }

    &__content {
      @apply text-center;
    }

  }

  .tiles--smaller & {

    &__title {
      @apply w-full;
      font-size: 22px;
      line-height: 26px;
    }

    &__content {
      @apply flex flex-wrap justify-center items-center mb-3;
    }

  }

  @screen sm {
    @apply w-1/3;

    .tiles--4 & {
      @apply w-1/4;
    }

  }

  @screen md {

    &__content {
      p {
        font-size: 18px;
        line-height: 1.6;
      }
    }

    .tiles--6 & {
      &__content {
        @apply text-left;
      }

      &__action {
        @apply justify-start;
      }

    }

  }

  @screen lg {

    .tiles--6 & {
      @apply w-1/6;
    }

  }

}