.news-index {
  @apply pb-16;
}

.news-pagination {
  @apply py-8 flex justify-between;

  ul {
    @apply flex justify-center w-full;

    li {

    }

    span,
    a {
      @apply flex justify-center items-center rounded-full font-bold;
      width: 48px;
      height: 48px;
      transition: all 0.2s linear;
    }

    a:hover {
      @apply bg-lightblue text-white;
    }

    span.current {
      @apply bg-green text-white;
    }

    a.next-prev {
      path {
        fill: theme('colors.darkgrey');
        transition: all 0.2s linear;
      }

      &:hover {
        path {
          fill: white;
        }
      }
    }
  }

}