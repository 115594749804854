.cta {

  &__title {
    @apply text-center mb-6 text-purple;
  }

  &__content {
    @apply text-center mb-6;

    p {
      @apply mb-0;
    }
  }

  &__actions {
    @apply flex flex-wrap justify-center;

    .button {
      @apply mx-3 mb-3;

      &:last-child {
        @apply mb-0;
      }
    }
  }

  &--darkpurple {
    @apply bg-darkpurple text-white;

    .cta {
      &__title {
        @apply text-white;
      }
    }
  }

  &--gradientpurple {
    @apply text-white;
    background: linear-gradient(164deg, #211F6E, #AA33C6 97%);

    .cta {
      &__title {
        @apply text-white;
      }
    }
  }


  @screen md {

    &__actions {

      .button {
        @apply mb-0;
      }

    }

  }

}