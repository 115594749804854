.button {
  @apply rounded-3xl px-8 inline-flex items-center font-button;
  padding-top: 5px;
  padding-bottom: 5px;
  transition: all 0.2s linear;


  &--large {
    @apply px-10 text-lg rounded-full font-bold justify-center;
    min-width: 200px;
    padding-top: 9px;
    padding-bottom: 9px;
  }

  span {
    @apply inline-flex items-center;

    &.hidden {
      display: none!important;
    }
  }

  &--hollow {
    @apply border-2;

    &.button--black {
      @apply border-black;

      &:hover {
        @apply border-blue bg-blue text-white;
      }
    }

    &.button--blue {
      @apply border-blue text-blue;

      &:hover {
        @apply border-blue bg-blue text-white;
      }
    }

    &.button--white {
      @apply border-white border-2 text-white;

      &:hover {
        @apply border-blue bg-blue text-white;
      }
    }

    &.button--hover-to-blue {
      &:hover {
        @apply border-blue text-white bg-blue;
      }
    }

  }

  &--solid {
    @apply border;

    &.button--primary {
      @apply border-yellow bg-yellow text-blue;

      &:hover {
        @apply border-blue bg-blue text-white;

        &.button--on-blue {
          @apply border border-white bg-transparent text-white;
        }

        &.button--on-green {
          @apply border border-white bg-blue text-white;
        }
      }
    }

  }


  @screen md {
    &--large {
      @apply px-10 text-xl rounded-full font-bold justify-center;
      min-width: 200px;
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }


}
