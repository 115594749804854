.image-content {

  &__title {
    @apply text-purple;
  }

  position: relative;
  .pattern--none {
    display: none;
  }

  .pattern--right {
    width: 22%;
    @apply absolute h-auto bottom-0 right-0;
  }

  .pattern--left {
    width: 22%;
    transform: scale(-1, 1);
    @apply absolute h-auto bottom-0 left-0;
  }

  &--green {
    @apply pt-15 pb-14;
    background: linear-gradient(120.53deg, #D8EFF5 0%, #EEF5E5 98.43%);

    .image-content__title {
      @apply text-imagecontentblue;
    }

    .image-content__text {
      @apply text-darkgrey;
    }
  }

  &__image {
    z-index: 1;
    @apply mb-9;
    background-size: cover;
    background-position: center bottom;
    margin-left: -24px;
    margin-right: -24px;
  }

  &__image-link {
    @apply relative block;

    svg {
      @apply absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    circle, path {
      @apply transition-all;
    }

    &:hover {
      circle {
        fill: theme('colors.lightblue');
      }

      path {
        fill: white;
      }
    }
  }

  &__content {

    h2 {
      @apply mb-6;
    }

  }

  &__text {
    font-size: 16px;
    line-height: 24px;
  }

  &__actions {
    @apply pt-3;

    .button {
      @apply mr-6 mb-4;

      &:last-child {
        @apply mr-0;
      }
    }
  }

  ul {
    li {
      @apply relative mb-2 pl-6;

      &:before {
        @apply bg-darkgrey absolute mr-3;
        content: '';
        width: 13px;
        height: 1px;
        top: 13px;
        left: 0;
      }
    }
  }

  &--darkpurple {
    @apply pt-15 pb-14 bg-darkpurple text-white;

    .image-content__title {
      @apply text-white;
    }

    p a, ul a {
        padding-bottom: 1px;
          transition: all 0.2s linear;
        @apply text-white border-b-2 border-white;
        &:hover {
            @apply border-transparent no-underline text-white;
        }
    }

    ul {
      li {
        &:before {
          @apply bg-white;
        }
      }
    }
  }

  &--gradientpurple {
      background: linear-gradient(20deg, #211F6E 3.56%, #AA33C6 61.01%, #F36E5B 92.03%);

      .image-content__title,
      .image-content__text {
        @apply text-white;
      }

      p a, ul a {
          padding-bottom: 1px;
            transition: all 0.2s linear;
          @apply text-white border-b-2 border-white;
          &:hover {
              @apply border-transparent no-underline text-white;
          }
      }

      // .pattern--left, .pattern--right {
      //   width: 38%;
      // }

  }

  @screen md {

    .container {
      @apply flex justify-between items-center;
    }

    &--right {
      .container {
        @apply flex-row-reverse;
      }
    }

    &__image {
      @apply mb-0 px-6;
      width: 52%;
    }

    &__content {
      width: 44%;
    }

    &__text {
      font-size: 18px;
      line-height: 29px;
    }

  }

}
