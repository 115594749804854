.element-block {

  @apply py-10;

  &__title {
    @apply text-center text-purple;
  }

  @screen md {
    @apply py-16;
  }
}

[class*="__content"] {
  ul {

    li {
      @apply relative mb-2 pl-6;

      &:before {
        @apply bg-darkgrey absolute mr-3;
        content: '';
        width: 13px;
        height: 1px;
        top: 13px;
        left: 0;
      }
    }

  }

  a {
    @apply text-purple;
  }

  a:hover {
    @apply text-darkpurple underline;
  }


    .button:hover {
        @apply no-underline;
    }
}
