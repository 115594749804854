body {
  @apply font-body text-base leading-6 text-darkgrey; // text-base
  line-height: 1.6;
}

p, ul, ol {
  @apply mb-4;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  @apply font-headings;
   //font-family:"Helvetica Neue LT W05_53 Ext";
  //font-weight: 700;
}

h1, .h1 {
  font-size: 32px;
  line-height: 38px;
}

h2, .h2 {
  font-size: 28px;
  line-height: 34px;
}

h3, .h3 {
  font-size: 24px;
  line-height: 28px;
}

h4, .h4 {
  font-size: 20px;
  line-height: 24px;
}

h5, .h5 {
  font-size: 16px;
  line-height: 20px;
}

h6, .h6 {
  font-size: 16px;
  line-height: 20px;
}

ol {
  list-style: decimal;
  list-style-position: inside;
}

@screen md {

  body {
    @apply text-lg leading-6;
  }

  h1, .h1 {
    font-size: 40px;
    line-height: 48px;
  }

  h2, .h2 {
    @apply mt-5;
    font-size: 40px;
    line-height: 48px;
  }

  h3, .h3 {
    font-size: 28px;
    line-height: 34px;
  }

  h4, .h4 {
    font-size: 22px;
    line-height: 26px;
  }

  h5, .h5 {
    font-size: 18px;
    line-height: 22px;
  }

  h6, .h6 {
    font-size: 18px;
    line-height: 22px;
  }
}