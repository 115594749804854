.header {
  @apply absolute z-50 w-full;

  &__container {
    @apply flex items-center justify-between py-8;
  }

  &__hamburger {
    @apply flex justify-center items-center w-8 h-8;

    .header__open {
      @apply block;
    }

    .header__close {
      @apply hidden;
    }

    .active-mm & {
      .header__open {
        @apply hidden;
      }

      .header__close {
        @apply block;
      }
    }

  }

  &__logo {
    width: 195px;

    a {
      @apply block;
    }

    svg {
      @apply w-full h-auto;
    }
  }

  &__nav {
    @apply hidden;
  }

  &__nav-list {
    @apply flex justify-start mb-0;
  }

  &__nav__item {
    @apply ml-8;

    &:first-child {
      @apply ml-0;
    }

    &:hover {
      .header__nav__anchor {
        @apply border-white;
      }

      .header__sub-nav {
        @apply block;
      }
    }
  }

  &__nav__item--dropdown {
    @apply relative;
  }

  &__nav__anchor {
    @apply block py-2 border-b border-transparent text-white font-bodymedium;

    &.active {
      @apply border-white;
    }
  }

  &__sub-nav {
    @apply absolute hidden;
    width: 280px;
    padding-top: 14px;

    ul {
      @apply py-6 rounded-lg mb-0;
      background: white;
    }

    a {
      @apply text-darkgrey;
    }

    li {

      &.active {
        a {
          @apply text-purple bg-white;
        }
      }

    }

    a {
      @apply text-black block px-6 py-2;

      &:hover {
        @apply text-white bg-purple;
      }
    }
  }

  a {
    font-size: 19px;
    letter-spacing: 0.005rem;
  }


  @screen lgx {

    &__logo {
      width: 240px;
    }

    &__hamburger {
      @apply hidden;
    }

    &__container {
      @apply py-14;
    }

    &__nav {
       @apply block;
    }

  }

}