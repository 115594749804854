.staff {

  &__title {
    @apply mb-16;
  }

  &__grid {
    @apply flex flex-wrap -my-4;
  }

}

.staff-card {

  @apply w-full px-4 mb-9;

  &__title {
    @apply flex justify-between mb-6;

    h3 {
      @apply text-blue font-bodymedium cursor-pointer;
      font-size: 22px;
    }

    &__left {
      @apply mr-6;
    }

    &__button {
      @apply w-6 h-6 relative flex justify-center items-center cursor-pointer ml-4;
      top: 6px;
    }

  }

  &__job-title {
    @apply text-blue font-bodymedium;
    font-size: 18px;
    line-height: 22px;
  }

  &__buttons {
    @apply flex -mx-2 mb-0;

    li {
      @apply mx-2;
    }

    a {
      @apply flex items-center justify-center w-10 h-10 bg-yellow;
      border-radius: 50%;

      path {
        fill: #211F6E;
      }

      &:hover {
        @apply bg-blue;

        path {
          fill: #ffff;
        }
      }
    }
  }

  &__content {
    @apply hidden pb-6 pr-8;
  }

  &.active & {
    &__content {
      @apply block;
    }
  }

  .expand {
    @apply transition-all w-6 h-6 rounded-full flex justify-center items-center;
  }

  .expand:hover {
    @apply bg-purple;

    path {
      fill: white;
    }
  }

  .collapse:hover {
    rect {
      @apply transition-all;
      fill: white;
    }

    path {
      @apply transition-all;
      fill: theme('colors.darkpurple');
    }
  }

  &.active {

    .expand {
      @apply hidden;
    }

    .collapse {
      @apply flex;
    }

    .staff-card__inner {
      border-bottom: 2px solid theme('colors.purple');
    }

    h3 {
      @apply text-purple;
    }
  }

  &__inner {
    border-bottom: 2px solid theme('colors.darkpurple');
  }

  @screen md {
    @apply w-1/2;
  }

  @screen lg {
    @apply w-1/4;
  }

}